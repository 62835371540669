import { configureStore, Tuple } from "@reduxjs/toolkit";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import rootReducer from "reducers";
import { thunk } from "redux-thunk";
import { createHashHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import settings from "reducers/settingsReducer";
import { routerReducer } from "react-router-redux";

export const history = createHashHistory();
const configureStoreProd = (initialState) => {
  const reactRouterMiddleware = routerMiddleware(history);

  const store = configureStore({
    reducer: {
      settings,
      routing: routerReducer,
      router: connectRouter(history),
    },
    preloadedState: initialState,
    middleware: () =>
      new Tuple(reduxImmutableStateInvariant(), thunk, reactRouterMiddleware),
  });

  return store;
};

const configureStoreDev = (initialState) => {
  const reactRouterMiddleware = routerMiddleware(history);

  const store = configureStore({
    reducer: {
      settings,
      routing: routerReducer,
      router: connectRouter(history),
    },
    preloadedState: initialState,
    middleware: () =>
      new Tuple(reduxImmutableStateInvariant(), thunk, reactRouterMiddleware),
  });

  return store;
};

const configureReduxStore =
  process.env.NODE_ENV === "production"
    ? configureStoreProd
    : configureStoreDev;

export default configureReduxStore;
