import { message } from "antd";
import { addItem, removeItem } from "./LocalStorage";
import { LogError } from "./APIRequests";

const API_URL = process.env.REACT_APP_API_URL;

export function UserLoggedIn() {
  return fetch(API_URL + "/auth/login-status", {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => {
      return result.status === 200;
    })
    .catch((err) => {
      message.error(
        "Unable to authenticate user login. Please contact your administrator."
      );
      console.error(err);
      LogError(err, "Auth API Requests");
      return false;
    });
}

export function Login(data) {
  return fetch(API_URL + "/auth/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => {
      if (result && result.user) {
        addItem("user", JSON.stringify(result.user));
      }

      return result;
    })
    .catch((err) => {
      message.error(
        `Unable to login user. Please try after some time or contact your administrator.`
      );
      console.error(err);
      LogError(err, "Auth API Requests");
      return undefined;
    });
}

export function Verify(data, url) {
  return fetch(API_URL + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => {
      if (result && result.status !== 200) {
        message.error(result.message);
      }
      return result.status === 200;
    })
    .catch((err) => {
      message.error(
        `Unable to verify user. Please try after some time or contact your administrator.`
      );
      console.error(err);
      LogError(err, "Auth API Requests");
      return undefined;
    });
}

export function AcceptTermsConditions(data, url) {
  return fetch(API_URL + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => {
      if (result.status === 200) {
        message.success(result.message);
      } else {
        const messages = result.message.split("\n");
        messages.forEach((value) => {
          message.error(value);
        });
      }
      return result.status === 200;
    })
    .catch((err) => {
      message.error(
        `Unable to accept terms & conditions. Please contact your administrator.`
      );
      console.error(err);
      this.LogError(err, "API Requests");
      return false;
    });
}

export function RegisterUser(data) {
  return fetch(API_URL + "/auth/register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((err) => {
      message.error(
        `Unable to set password for user. Please try after some time or contact your administrator.`
      );
      console.error(err);
      LogError(err, "Auth API Requests");
      return undefined;
    });
}

export function Logout() {
  removeItem("user");
  return fetch(`${API_URL}/auth/logout`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => {
      return result.status === 200;
    })
    .catch((err) => {
      console.error(err);
      LogError(err, "Auth API Requests");
      return true;
    });
}

export function CheckAuthorization(data) {
  return fetch(API_URL + `/authorization`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((result) => {
      return result.status;
    })
    .catch((err) => {
      console.error(err);
      LogError(err, "Auth API Requests");
      return false;
    });
}

export function SideNavigations() {
  return fetch(API_URL + "/authorization/side-navigations", {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.error(err);
      LogError(err, "Auth API Requests");
      return [];
    });
}
