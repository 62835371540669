import { connectRouter } from "connected-react-router";
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";

const settings = require("./settingsReducer");

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings,
    routing: routerReducer,
  });

export default rootReducer;
