export function addItem(key, value) {
    localStorage.setItem(key, value);
};

export function getItem(key) {
    return localStorage.getItem(key);
};

export function removeItem(key) {
    return localStorage.removeItem(key);
};

export function keyExists(key) {
    return key in localStorage;
};

export function clearAll() {
    localStorage.clear();
};