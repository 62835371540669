import React from 'react';
import moment from 'moment';
import * as AntdIcons from '@ant-design/icons';
import { StarFilled } from '@ant-design/icons';

export const getAntdIcon = (name) => {
    try{
        const DynamicIcon = AntdIcons[name];
        return DynamicIcon && <DynamicIcon />;
    }
    catch(err) { throw err; }
}

export function buildUserFilter(unit, student){
    try{
        const data = student ? buildUserIds(unit) : [{ _id: unit._id }];
        let conditions = [];
        
        for(let i in data){
            conditions.push({"_id": data[i]._id});
        }

        return conditions.length > 0 ? `filter={"$or":${JSON.stringify(conditions)}}` : undefined;
    }
    catch(err) { throw err; }
}

export function buildStudentFilter(unit){
    try{
        const data = buildUserIds(unit);
        let conditions = [];
        
        for(let i in data){
            conditions.push({[data[i].contactType.toLowerCase()]: data[i]._id});
        }

        return conditions.length > 0 ? `filter={"$or":${JSON.stringify(conditions)}}` : undefined;
    }
    catch(err) { throw err; }
}

function buildUserIds(record) {
    try{
        let userIds = [];

        if(record.father) { userIds.push({ contactType: 'Father', _id: record.father._id ? record.father._id : record.father }) };
        if(record.mother) { userIds.push({ contactType: 'Mother', _id: record.mother._id ? record.mother._id : record.mother }) };
        if(record.guardian) { userIds.push({ contactType: 'Guardian', _id: record.guardian._id ? record.guardian._id : record.guardian }) };
        
        return userIds;
    }
    catch(err) { throw err; }
}

export function getUserContactType(record, id){
    try{
        let contactType = Object.keys(record).find(key => (JSON.stringify(record[key]).indexOf('_id') !== -1 && JSON.stringify(record[key]).indexOf(id) !== -1) || record[key] === id);
        contactType = `${contactType[0].toUpperCase()}${contactType.slice(1)}`; 
        return contactType;
    }
    catch(err) { throw err; }
}

export function getAge(birthday){
    try{
        if(birthday){
            var ageDifMs = Date.now() - moment(birthday);
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
    }
    catch(err) { throw err; }
}

export function getAgeByDate(birthday, date){
    try{
        if(birthday){
            var ageDifMs = moment(date) - moment(birthday);
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
    }
    catch(err) { throw err; }
}

export function decodeHTMLEntities(str) {
    try{
        if(str && typeof str === 'string') {
            var element = document.createElement('div');
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }
    
        return str;
    }
    catch(err) { throw err; }
}

export function getStars(rating){
    try{
        let stars = [];
        for(let i = 0; i < rating; i++){
            stars.push(<StarFilled key={i} className="mr-1" style={{ fontSize: 16, color: 'gold' }} />)
        }

        return stars;
    }
    catch(err) { throw err; }
}

export function toTitleCase(str){
    try{
        return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
    }
    catch(err) { throw err; }
};