import React from "react";
import QueueAnim from "rc-queue-anim";
import { Button } from "antd";
// import { getLandingPage } from 'helpers/CurrentUser';
import { useNavigate } from "react-router-dom";

const Page = () => {
  const navigate = useNavigate();
  const goToLandingPage = () => {
    navigate("/user/login");
  };

  return (
    <div className="page-err">
      <QueueAnim type="bottom" className="ui-animate">
        <div key="1">
          <div className="err-container text-center">
            <div className="err-code-container">
              <div className="err-code">
                {" "}
                <h1>404</h1>{" "}
              </div>
            </div>
            <h2>Sorry, page not found</h2>
            <Button onClick={goToLandingPage}>Go Back to Home Page</Button>
          </div>
        </div>
      </QueueAnim>
    </div>
  );
};

export default Page;
