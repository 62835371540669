import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router";
import {
  Routes,
  Route,
  useNavigate,
  matchRoutes,
  Link,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "components/Loading";
import Page404 from "../routes/exception/routes/404";

// 3rd
import "styles/antd-compiled.css";
import "styles/bootstrap/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";
import "styles/vendors.scss";

import PrivateRoute from "./PrivateRoute";
import ReRoute from "./ReRoute";

let AsyncAppLayout = loadable({
  loader: () => import("components/Layout/AppLayout/index"),
  loading: LoadingComponent,
});

let AsyncAccount = loadable({
  loader: () => import("routes/user"),
  loading: LoadingComponent,
});
let Async404Exception = loadable({
  loader: () => import("routes/exception/routes/404"),
  loading: LoadingComponent,
});

// let AsyncDashboard = loadable({
//   loader: () => import("routes/dashboard/index"),
//   loading: LoadingComponent,
// });

// let AdminDashboard = loadable({
//   loader: () => import("routes/dashboard/routes/admin/index"),
//   loading: LoadingComponent,
// });

// const UserAccount = React.lazy(() => import("routes/user/index"));

// const router = createBrowserRouter(AppRoutes);

function App(props) {
  const location = useLocation();
  const navigate = useNavigate();
  // const [{ route }] = matchRoutes(routes, location);

  // const authroutes = AuthRoutes();
  // const exceptionroutes = ExceptionRoutes();
  // const dashboardroutes = DashboardRoutes();

  const [referrer, setReferrer] = React.useState(null);

  const root = location.pathname === "/" ? true : false;

  // console.log(root);

  useEffect(() => {
    // console.log("home:", location.pathname);

    if (location && !location.pathname.includes("/user/login")) {
      setReferrer(location.pathname);
    }
  }, []);

  return (
    <>
      <div id="app">
        {/* <RouterProvider router={router} /> */}
        <Routes>
          <Route path="*" element={<ReRoute />} />
          <Route path={`user/*`} element={<AsyncAccount />} />
          <Route
            path={`app/*`}
            element={
              <PrivateRoute Component={AsyncAppLayout} referrer={referrer} />
            }
          />
          <Route element={<Async404Exception />} />
          {/* <Route path="*" element={<Page404 />}></Route>
          {authroutes}
          {exceptionroutes} */}

          {/* <Route path={`/user`} element={<UserRoutes />}>
            <Route path="nested" element={<NestedLayout />} />
          </Route> */}
          {/* <Route path={`user`}>{authroutes}</Route> */}
          {/* <PrivateRoute
            path={`app/*`}
            referrer={referrer}
            element={<AsyncAppLayout />}
          /> */}

          {/* <Route path={`app`} element={<PrivateRoute />}>
            <Route path={`dashboard`} element={<AsyncAppLayout />}>
              <Route
                path="admin"
                element={
                  <AuthorizationRoute
                    moduleName="Admin Dashboard"
                    component={AdminDashboard}
                  />
                }
              ></Route>
            </Route>
          </Route> */}
          {/* <Route
            path={`app`}
            element={<PrivateRoute Component={AsyncAppLayout} />}
          /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
