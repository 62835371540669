import { message } from 'antd';
import { toTitleCase } from './AppHelper';

const API_URL = process.env.REACT_APP_API_URL;

export function GetElement(name, id, query){
    const element = toTitleCase(name.substr(0, name.length - 1));
    return fetch(`${API_URL}/${name.toLowerCase()}/${id}${query}`, {
        credentials: 'include'
    })    
    .then(res => res.json())
    .then((result) => {
        if(result.status){
            message.error(`Error while fetching the data. Please try after sometime or contact your administrator`, 3);
            return undefined;
        }
        else{
            return result;
        }
    })
    .catch(err => {
        message.error(`Unable to fetch ${element}. Please contact your administrator.`);
        console.error(err);
        this.LogError(err, 'API Requests');
        return undefined;
    })
};

export function GetAllElements(name, path){
    return fetch(API_URL + path,{
        credentials: 'include'
    })
    .then(res => res.json())
    .then((result) =>{
        if(result.status){
            message.error(`Error while fetching the data. Please try after sometime or contact your administrator`, 3);
            return [];
        }
        else{
            return result;
        }
    })
    .catch(err => {
        message.error(`Unable to fetch ${name}. Please contact your administrator.`);
        console.error(err);
        this.LogError(err, 'API Requests');
        return [];
    })
};

export function AddElement(name, data){
    const element = toTitleCase(name.substr(0, name.length - 1));
    return fetch(API_URL + `/${name}`,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
        if(result.status === 201){
            message.success(result.message);
        }
        else{
            const messages = result.message.split('\n');
            messages.forEach(value => {
                message.error(value);
            });
        }
        return result.data ? result : result.status === 201;
    })
    .catch(err => {
        message.error(`Unable to save ${element}. Please contact your administrator.`);
        console.error(err);
        this.LogError(err, 'API Requests');
        return false;
    });
};

export function ModifyElement(name, id, data){
    const element = toTitleCase(name.substr(0, name.length - 1));
    return fetch(API_URL + `/${name}/${id}`,{
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
        if(result.status === 200){
            message.success(result.message);
        }
        else{
            const messages = result.message.split('\n');
            messages.forEach(value => {
                message.error(value);
            });
        }
        return result.status === 200;
    })
    .catch(err => {
        message.error(`Unable to save ${element}. Please contact your administrator.`);
        console.error(err);
        this.LogError(err, 'API Requests');
        return false;
    });
};

export function DeleteElement(name, id){
    const element = toTitleCase(name.substr(0, name.length - 1));
    return fetch(API_URL + `/${name}/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include'
    })
    .then(res => res.json())
    .then((result) => {
        if(result.status === 200){
            message.success(result.message);
        }
        else{
            message.error(result.message);
        }
        return result.status === 200;
    })
    .catch(err => {
        message.error(`Unable to delete ${element}. Please contact your administrator.`);
        console.error(err);
        this.LogError(err, 'API Requests');
        return false;
    });
};

export const LogError = (error, moduleName) => {
    const data = { source: 'Web App', message: `${error.message}\n${moduleName}\n${window.location.href}\n${error.toString()}` };

    return fetch(`${API_URL}/errorlogs`,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then((result) => {
        if(result.status !== 201){
            const messages = result.message.split('\n');
            messages.forEach(value => {
                console.error(value);
            });
        }
        return result.data ? result : result.status === 201;
    })
    .catch(err => {
        console.error(err);
        return false;
    });
};