// import React, { useEffect } from "react";
// import { Navigate, Route } from "react-router-dom";
// // import { UserLoggedIn } from "../helpers/AuthAPIRequests";
// import { withRouter } from "./withRouter";
// import { UserLoggedIn } from "helpers/AuthAPIRequests";

// // class PrivateRoute extends React.Component {
// //   state = {
// //     loaded: false,
// //     isAuthenticated: false,
// //   };

// //   authenticate() {
// //     UserLoggedIn()
// //       .then(async (result) => {
// //         await this.setState({ loaded: true, isAuthenticated: result });
// //       })
// //       .catch(() =>
// //         this.props.history.push({
// //           pathname: "/user/login",
// //           state: this.props.location.pathname,
// //         })
// //       );
// //   }

// //   componentDidMount() {
// //     this.authenticate();
// //   }

// //   render() {
// //     const { component: Component, ...rest } = this.props;
// //     const { loaded, isAuthenticated } = this.state;

// //     if (!loaded) return null;

// //     return (
// //       <Route
// //         {...rest}
// //         render={(props) => {
// //           return isAuthenticated ? (
// //             <Component {...props} />
// //           ) : (
// //             <Navigate
// //               to={{
// //                 pathname: "/user/login",
// //                 state: this.props.location.pathname,
// //               }}
// //             />
// //           );
// //         }}
// //       />
// //     );
// //   }
// // }

// // export default withRouter(PrivateRoute);

// const PrivateRoute = (props) => {
//   const [loaded, setLoaded] = React.useState(false);
//   const [isAuthenticated, setIsAuthenticated] = React.useState(false);

//   const authenticate = (props) => {
//     UserLoggedIn()
//       .then(async (result) => {
//         await this.setState({ loaded: true, isAuthenticated: result });
//       })
//       .catch(() =>
//         props.history.push({
//           pathname: "/user/login",
//         })
//       );
//   };

//   useEffect(() => {
//     authenticate();
//   }, []);

//   return <>{isAuthenticated && <Route {...props} />}</>;
// };

// export default withRouter(PrivateRoute);

import { UserLoggedIn } from "helpers/AuthAPIRequests";
import React, { useEffect } from "react";
import { Outlet, Navigate, Route, useNavigate } from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "components/Loading";

const PrivateWrapper = ({ Component }) => {
  const navigate = useNavigate();

  const [userStatus, setUserStatus] = React.useState({
    loaded: false,
    isAuthenticated: false,
  });

  const authenticate = () => {
    UserLoggedIn()
      .then((result) => {
        setUserStatus({
          loaded: true,
          isAuthenticated: result,
        });
      })
      .catch(() => {
        navigate("/user/login");
      });
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <>
      {userStatus.loaded && (
        <>
          {userStatus.isAuthenticated ? (
            <>
              <Component />
              {/* <Outlet /> */}
            </>
          ) : (
            <Navigate to="/user/login" />
          )}
        </>
      )}
    </>
  );
};

export default PrivateWrapper;
